

:root {
  --button-background: #EE1C81;
  --button-text: #FFFFFF;
  --background-global: #F5CAC3;
  --secondary-color: #F7EDE2;
  --text-form: #F7EDE2;
  --secondary-text: #3D3D3C;
}

.typeformButton {
  background-color: var(--button-background); /* Green */
  border: none;
  color: var(--button-text);
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}
